.checkChoice:checked + .checkChoiceLabel {
    border-color: #3e2d2a;
    .checkChoiceLabelCheck {
        background-color: #3e2d2a;
        img {
            display: block;
        }
    }
}

.checkChoiceLabel {
    .checkChoiceLabelCheck {
        height: px-to-rem(54px);
        width: px-to-rem(54px);
    }

    &:hover {
        border-color: #3e2d2a;

        .checkChoiceLabelCheck {
            background-color: #3e2d2a;
            img {
                display: block;
            }
        }
    }
}
.rc-slider-tooltip-arrow {
    display: none;
}

.rc-slider-dot {
    &:first-child {
        display: none;
    }
    &:last-child {
        display: none;
    }
}
