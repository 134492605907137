.preview-item {
  @apply mx-auto h-full w-full max-w-[360px] pl-9;
}

@screen md {
  .preview-item {
    @apply max-w-none;
  }
}

.preview-item-border {
  @apply ml-9 mt-4 h-[calc(100%-2rem)] w-px;
}

.swiper {
  --swiper-theme-color: #688f68;
  --swiper-navigation-color: #000;
  --swiper-navigation-sides-offset: 0px;
  --swiper-navigation-top-offset: 40%;
  --swiper-navigation-size: 30px;

  margin-left: -14px;
  margin-right: -14px;

  .swiper-pagination-bullet {
    @apply border border-green bg-white opacity-100;
  }

  .swiper-pagination-bullet-active {
    @apply bg-green;
  }
}

.swiper-pagination {
  position: static !important;
}

.swiper-wrapper {
  @apply mx-auto flex h-full items-stretch;
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-slide {
  height: auto !important;
}

.youtubeWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.screen-block-background {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: opacity 500ms;
}

.screen-block-children {
  background: rgba(0, 0, 0, 0.8);
}

.rc-slider-tooltip .rc-slider-tooltip-inner {
  min-width: 24px;
  height: 24px;
  font-size: 14px;
  @apply text-green;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 6px;
  box-shadow: none;
}

.rc-slider-handle-dragging {
  box-shadow: none !important;
}

@screen md {
  .swiper-slide-boarder .swiper-slide-active .preview-item-border {
    @apply border-r border-0.3;
  }

  .grid {
    .preview-item-border {
      @apply border-0.3;
    }

    .preview-item:not(:nth-child(2n)) {
      .preview-item-border {
        @apply max-lg:border-l;
      }
    }
  }
}

@screen lg {
  .swiper-slide-boarder {
    .swiper-slide-active,
    .swiper-slide-next {
      .preview-item-border {
        @apply border-r border-0.3;
      }
    }
  }

  .grid {
    .preview-item:not(:nth-child(3n)):nth-last-child(n + 1) {
      .preview-item-border {
        @apply border-l;
      }
    }
  }
}
