.cmsRichText {
  line-height: 1.5;
  a {
    color: #688f68;
    text-decoration: underline;
  }

  p,
  ul,
  ol {
    padding-bottom: px-to-rem(12px);
    font-size: px-to-rem(16px);
    line-height: 1.5rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  ul,
  ol {
    margin-left: px-to-rem(10px);
    padding-left: px-to-rem(10px);
  }

  ol {
    li {
      list-style: decimal;
      padding-bottom: px-to-rem(5px);
    }
  }

  ul {
    li {
      list-style: disc;
      padding-bottom: px-to-rem(5px);
    }
  }

  &.text-base {
    p,
    ul,
    ol {
      font-size: 1rem;
    }
  }

  h1,
  h2,
  h3 {
    margin-top: px-to-rem(20px);
    margin-bottom: px-to-rem(10px);
  }

  h2,
  h3 {
    &:first-child {
      margin-top: px-to-rem(10px);
    }
    @apply text-black;
    font-size: px-to-rem(22px);
  }

  h3 {
    line-height: 31px;
  }

  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.striking-headings {
    h2,
    h3 {
      @apply text-green;
      font-size: px-to-rem(36px);
    }
  }
}

.cmsRichText-lead {
  h1,
  h2,
  h3,
  h4 {
    @apply text-green;
  }

  h1,
  h2 {
    font-size: px-to-rem(32px);
    font-weight: 700;
    @apply -ml-[3px] uppercase;
  }
}

.cmsBody {
  img {
    max-height: 40vh;
  }
}

// .bg-color {
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     &:first-child {
//       margin-top: 0;
//     }
//   }
// }
