.product-bg-gradient-overlay {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 53.5%,
    #3e2d2a 100%
  );
  opacity: 0.25;
}

.product-bg-gradient-overlay-black {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 53.5%,
    #000000 100%
  );
  opacity: 0.25;
}

.discount {
  transform: translate(-35px, -160px);
}

.discountPrice {
  transform: translate(0px, -34px);
}

.aromaCrossLabelLeft {
  transform: rotate(-90deg) translate(-71px, -90px);
}

.aromaCrossLabelRight {
  transform: rotate(-90deg) translate(-71px, 74px);
}

.calendarIcon {
  width: 35px;
  height: 38px;
  margin-bottom: 10px;
}

.calendarDay {
  transform: translate(-26px, 0px);
}

.dateContainer {
  transform: translate(0, -30px);
}

.actionButton {
  min-width: 30px;
  min-height: 30px;
}
