@use 'sass:math';

// Import Tailwind first, to have access to its definitions (e.g. @screen).
@import './tailwind.scss';
@import './components/product/product.scss';
@import './components/cms/cms.scss';
@import './components/advice/advice.scss';
@import './components/common/common.scss';
@import './components/layout/nav/navbar.scss';
@import './components/filter/filter.scss';
@import './components/checkout/checkout.scss';

@font-face {
  font-family: 'noah';
  src:
    url('/static/fonts/noah/Noah-Bold.woff2') format('woff2'),
    url('/static/fonts/noah/Noah-Bold.woff2') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'noah';
  src:
    url('/static/fonts/noah/Noah-Regular.woff2') format('woff2'),
    url('/static/fonts/noah/Noah-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  line-height: 1.375;
  font-family: 'noah', sans-serif;
  @apply text-black;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar-track {
  background: #688f68;
}

@media screen and (max-width: 360px) {
  html {
    // issue with teasers
    // font-size: 12px;
  }
}

.font-regular {
  font-family: 'noah', sans-serif;
  @apply font-normal;
}

h1,
h2,
h3,
.font-heading {
  font-family: 'noah', sans-serif;
  @apply text-green;
  @apply uppercase;
}

h1,
.h1 {
  font-size: px-to-rem(32px);
  font-weight: 700;
  @apply -ml-[3px] uppercase;
}

h2,
.h2 {
  font-size: px-to-rem(28px);
  font-weight: 700;
}

h3,
.h3 {
  font-weight: 700;
  font-size: px-to-rem(22px);
}

h4,
.h4,
h5,
h6 {
  font-weight: 700;
  font-size: px-to-rem(16px);
}

hr {
  border-top: 1px solid #000;
  border-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

form:not(.not-styled),
.for:not(.not-styled) {
  @apply mb-9 rounded-lg bg-white p-10 text-left shadow-xl;
}

input,
.scroll-margin-50px,
.has-error {
  scroll-margin: 50px;
}

.text-input {
  @apply mb-1 mt-2 w-full appearance-none rounded-md border px-3 py-2 focus:border-green focus:outline-none focus:ring-green;

  &.compact {
    @apply my-0 lg:h-[26px] lg:px-2 lg:py-0 lg:text-[13px];

    &.text-area {
      @apply lg:h-auto;
    }
  }
}

.element-sublink {
  a {
    font-weight: bold;
    @apply cursor-pointer text-green;
  }
}

.link {
  @apply cursor-pointer text-green;
}

.enforce-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

main.hasBanner > div:first-of-type {
  padding-top: 10rem;
}

.banner {
  a {
    text-decoration: underline;
  }
}

.bg-brown3 {
  color: #fff;
}

main > div:first-of-type {
  padding-top: 103px;
}

@screen xs {
  main > div:first-of-type {
    padding-top: 100px;
  }
}

@screen lg {
  main > div:first-of-type {
    padding-top: 114px;
  }

  .checkout-container {
    @apply grid gap-20;
    grid-template-columns: [c1] 1fr [c2] 1fr [c3] auto [c4] 1fr [cEnd];
  }
}

@screen md {
  .filter-with-images-carousel {
    .swiper-slide {
      width: calc(50% - 5px) !important;
      margin-right: 10px;
    }
  }
}

button {
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
input[type='date']:not(.no-label):before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type='date']:focus:before,
input[type='date']:valid:before {
  content: '';
}
