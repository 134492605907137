.deliveryDateModeGrid {
  @apply grid gap-1;
  grid-template-columns: [c1] 1fr [c2] 1fr [cEnd];
  grid-template-rows: [r1] auto [r1] auto [rEnd];

  grid-template-areas:
    'deliveryDateMode-asap deliveryDateMode-asap'
    'deliveryDateMode-at-latest deliveryDate-at-latest'
    'deliveryDateMode-at deliveryDate-at'
    'deliveryDateMode-after deliveryDate-after';

  .deliveryDateMode-asap {
    height: 26px;
    grid-area: deliveryDateMode-asap;
  }

  .deliveryDateMode-at-latest {
    height: 26px;
    grid-area: deliveryDateMode-at-latest;
  }

  .deliveryDateMode-at {
    height: 26px;
    grid-area: deliveryDateMode-at;
  }

  .deliveryDateMode-after {
    height: 26px;
    grid-area: deliveryDateMode-after;
  }

  .deliveryDate-asap {
    height: 26px;
    grid-area: deliveryDate-asap;
  }

  .deliveryDate-at-latest {
    height: 26px;
    grid-area: deliveryDate-at-latest;
  }

  .deliveryDate-at {
    height: 26px;
    grid-area: deliveryDate-at;
  }

  .deliveryDate-after {
    height: 26px;
    grid-area: deliveryDate-after;
  }
}
