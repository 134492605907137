.mobile-navbar {
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.mobile-navbar-slide-up {
  animation-name: slideUp;
  animation-duration: 100ms;
  transform: translate(0, 0);
}

.mobile-navbar-slide-down {
  animation-name: slideDown;
  animation-duration: 100ms;
  transform: translate(0, -10rem);
}

.suggestion-content {
  max-height: calc(100vh - 11.5rem);
  overflow: hidden;
  display: flex;
  flex-flow: column wrap;
}

@keyframes slideDown {
  0% {
    transform: translate(0, -10rem);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -10rem);
    opacity: 1;
  }
}
