.filter-form-compact {
  .filter-body {
    @apply max-h-none py-3;
  }
}

.filter-dialog {
  @apply pt-6 lg:max-w-4xl lg:px-6 lg:pb-6 xl:max-w-5xl;
}

.filter-header {
  @apply px-6 lg:px-0;
}

.filter-body {
  @apply my-3 max-h-[27rem] overflow-y-auto overflow-x-hidden px-6 lg:my-0 lg:px-0;
}

.filter-quick {
  @apply pb-4;
}

.filter-header-0-1,
.filter-header-0-2 {
  @apply mt-5;
}

.filter-body-0-0,
.filter-body-0-1 {
  @apply mt-0;
}

@screen lg {
  .filter-dialog {
    @apply grid max-h-full gap-2 pb-5;
    grid-template-columns: [c1] 1fr [c2] 1fr [c3] 1fr [c4] 1fr [cEnd];
    grid-template-rows: [header1] 55px [body1] auto [header2] 55px [body1] auto [rEnd];

    grid-template-areas:
      'filter-quick     filter-header-2 filter-header-4 filter-header-6'
      'filter-quick     filter-body-2   filter-body-4   filter-body-6'
      'filter-header-1  filter-header-3 filter-header-5 .'
      'filter-body-1    filter-body-3   filter-body-5   .';

    .filter-quick {
      grid-area: filter-quick;
      @apply pb-3;
    }

    .filter-body {
      min-height: 100px;
    }

    .filter-header-1 {
      grid-area: filter-header-1;
    }

    .filter-body-1 {
      grid-area: filter-body-1;
    }

    .filter-header-2 {
      grid-area: filter-header-2;
    }

    .filter-body-2 {
      grid-area: filter-body-2;
    }

    .filter-header-3 {
      grid-area: filter-header-3;
    }

    .filter-body-3 {
      grid-area: filter-body-3;
    }

    .filter-header-4 {
      grid-area: filter-header-4;
    }

    .filter-body-4 {
      grid-area: filter-body-4;
    }

    .filter-header-5 {
      grid-area: filter-header-5;
    }

    .filter-body-5 {
      grid-area: filter-body-5;
    }

    .filter-header-6 {
      grid-area: filter-header-6;
    }

    .filter-body-6 {
      grid-area: filter-body-6;
    }
  }
}
