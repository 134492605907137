@use 'sass:math';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$base: 16px;
@function px-to-rem($px) {
  @return math.div($px, $base) * 1rem;
}

.text-last-center {
  text-align-last: center;
}

.tap-color-none {
  -webkit-tap-highlight-color: transparent;
}

.bg-green-gradient-1 {
  // background: linear-gradient(360deg, #cbd9cb 0%, #e7ede7 100%);
}

.bg-beige-gradient-1 {
  // background: linear-gradient(360deg, #e3dbc8 0%, #fbf9f2 100%);
}

.bg-red-gradient-1 {
  // background: linear-gradient(360deg, #eed1cb 0%, #fcf2ee 100%);
}

.bg-gold-gradient {
  // background: linear-gradient(360deg, #b09257 0%, #d4a649 100%);
}

.bg-silver-gradient {
  // background: linear-gradient(360deg, #acadad 0%, #d8dbdb 100%);
}

.bg-black-transparent-25 {
  // background: rgba(0, 0, 0, 0.25);
}

.bg-white-transparent-90 {
  // background: rgba(255, 255, 255, 0.9);
}

.leading-0 {
  line-height: 0;
}

.text-22px {
  font-size: px-to-rem(22px);
}

.h-9 {
  width: 2.25rem;
}

.h-13 {
  height: 3.25rem;
}

.w-9 {
  width: 2.25rem;
}

.mx-015 {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.mt-9 {
  margin-top: 2.8125rem;
}

.py-3px {
  padding-top: px-to-rem(3px);
  padding-bottom: px-to-rem(3px);
}

.mr-2px {
  margin-right: px-to-rem(2px);
}

.mb-10px {
  margin-bottom: px-to-rem(10px);
}

.w-18px {
  width: px-to-rem(18px);
}

.h-30px {
  height: px-to-rem(30px);
}

.w-30px {
  width: px-to-rem(30px);
}

.min-w-30px {
  min-width: px-to-rem(30px);
}

.min-h-30px {
  min-height: px-to-rem(30px);
}

.max-w-30px {
  max-width: px-to-rem(30px);
}

.max-w-250px {
  max-width: px-to-rem(250px);
}

.w-36px {
  width: px-to-rem(36px);
}

.h-46px {
  height: px-to-rem(46px);
}

.w-46px {
  width: px-to-rem(46px);
}

.h-200px {
  height: 200px;
}

.h-260px {
  height: px-to-rem(260px);
}

.max-w-8xl {
  max-width: 90rem;
}

.max-w-240px {
  max-width: px-to-rem(240px);
}

.w-300px {
  width: px-to-rem(300px);
}

.max-w-300px {
  max-width: px-to-rem(300px);
}

.max-w-350px {
  max-width: px-to-rem(350px);
}

.shadow-all {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.2),
    0 6px 30px 5px rgba(0, 0, 0, 0.14), 0 8px 10px -5px rgba(0, 0, 0, 0.12);
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.w-fit {
  width: fit-content;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.border-b-3 {
  border-bottom-width: 3px;
}

.text-xxs {
  font-size: 0.625rem;
}

.min-h-270px {
  min-height: px-to-rem(270px);
}

.min-h-330px {
  min-height: px-to-rem(330px);
}

.min-h-440px {
  min-height: px-to-rem(440px);
}

.-m--1px {
  margin-top: -1px;
}

.min-h-52px {
  min-height: px-to-rem(52px);
}

.shadow-inner-b {
  box-shadow: inset 0 -2rem 2rem -1rem rgba(0, 0, 0, 0.25);
}

.aspect-16-9 {
  width: 100%;
  padding-top: 56.25%;
}

.rounded-lg-img img {
  @apply rounded-lg;
}

.left--1px {
  left: -1px;
}

.max-word-spacing {
  word-spacing: 30px;
}

@media screen and (max-width: 360px) {
  .xs\:flex-w-40px {
    flex: 0 0 px-to-rem(40px);
  }
}
